import React, { useEffect, useState } from "react";
import useScrollPosition from "../../../hooks/useScrollPosition/useScrollPosition";
import "./FundingApplicationDecorations.scss";

const FundingApplicationDecorations = () => {
  const [presentationStep, setPresentationStep] = useState(0);
  const scrollPosition = useScrollPosition();

  useEffect(() => {
    setPresentationStep(scrollPosition);
  }, [scrollPosition]);

  return <div className="funding-application-decorations"></div>;

  // @todo Confirm if we need and if not delete them (13 sept 2024)
  // return (
  //   <div className="funding-application-decorations">
  //     <div className="d1 white circle"></div>

  //     <div className="d2 blue circle"></div>

  //     <div className="d4 half circle">
  //       <div className="yellow circle"></div>
  //     </div>

  //     <div className="d5 half circle">
  //       <div className="light-blue circle"></div>
  //     </div>

  //     <div className="d6 yellow circle"></div>

  //     <div className="d9 light-blue circle"></div>

  //     <div className="d10 yellow circle"></div>

  //     <div className="d11">
  //       <div className="__d1"></div>
  //     </div>
  //     <div className="d12"></div>

  //     <div className="d13">
  //       <div className="__d1"></div>
  //     </div>
  //     <div className="d14"></div>
  //   </div>
  // );
};

export default FundingApplicationDecorations;
