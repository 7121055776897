import { StepButton } from "@mui/material";
import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import React from "react";
import { getStepIndex } from "../../helpers/steps";

const steps = [
  {
    stepId: "register",
    label: "Profile Info",
    href: "/funding-application#register",
  },
  {
    stepId: "firstStep",
    label: "School & Program",
    href: "/funding-application#firstStep",
  },
  {
    stepId: "stepOneFinished",
    label: "View Your Offer",
    href: "/dashboard#stepOneFinished",
  },
  {
    stepId: "info",
    label: "Additional Info",
    href: "/funding-application#info",
  },
  {
    stepId: "upload",
    label: "Upload documents",
    href: "/funding-application#documents",
  },
];

const FundingApplicationStepper = ({ activeStep = "register" }) => {
  const getStepHref = (stepIndex) => {
    const step = steps[stepIndex];

    return step.href;
  };

  let activeStepOverride = getStepIndex(activeStep);
  let activeStepIndex = steps.findIndex(
    (step) => step.stepId === activeStepOverride
  );

  if (activeStep === "terminated") {
    activeStepIndex = activeStepIndex + 1;
  }

  return (
    <div className="stepper-wrapper">
      <Stepper activeStep={activeStepIndex} alternativeLabel>
        {steps.map(({ stepId, label, href }, index) => (
          <Step key={stepId}>
            <StepButton href={getStepHref(index)}>{label}</StepButton>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default FundingApplicationStepper;
