import { Stack } from "@mui/system";
import pdfIcon from "../../../assets/icons/pdf.svg";
import downloadIcon from "../../../assets/icons/download.svg";

export const patriotActContent = (
  <Stack direction="column" gap={2}>
    <p>
      USA PATRIOT ACT NOTICE: IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING
      A NEW ACCOUNT OR CHANGING AN EXISTING ACCOUNT
    </p>
    <p>
      To help the government fight the funding of terrorism and money laundering
      activities, Section 326 of the USA PATRIOT ACT, a Federal Law, requires
      all financial institutions to obtain, verify, and record information that
      identifies each person who opens an account or changes an existing
      account. This federal requirement applies to all new customers and current
      customers. This information is used to assist the United States government
      in the fight against the funding of terrorism and money-laundering
      activities.
    </p>
    <p>
      <span style={{ fontWeight: "bold" }}>What this means to you: </span>when
      you open an account or change an existing account, we will ask each person
      for their name, physical address, mailing address, date of birth, and
      other information that will allow us to identify them. We will ask to see
      each person’s driver’s license and other identifying documents and copy or
      record information from each of them.
    </p>
  </Stack>
);

export const TCPAContent = (
  <p>
    By checking this box, I expressly agree and consent to receive
    informational, marketing or promotional calls, or text messages from YELO
    Funding, Inc. or its affiliates (“YELO”) from an automatic telephone dialing
    system and/or prerecorded messages at the phone number I have provided in
    the website, regardless of this number being listed on any federal or state
    Do Not Call registry. I understand that my consent is not a condition of
    purchasing any goods or services. I understand that I may revoke this
    consent at any time by contacting YELO at{" "}
    <a href="mailto:info@yelofunding.com">info@yelofunding.com</a> or Reply
    “STOP” to any text messages to cancel.
  </p>
);

export const termsAndConditionsContent = (
  <Stack direction="column" gap={2}>
    <Stack direction="column" sx={{ fontStyle: "italic" }}>
      <p> Last updated: March 1, 2023.</p>
      <p>Please check back for updates and changes.</p>
    </Stack>

    <p>
      If you have any questions regarding these Terms of Use, the website, or
      the Service, please contact us at{" "}
      <a href="mailto:info@yelofunding.com">info@yelofunding.com</a>.
    </p>

    <Stack direction={"column"} gap={2}>
      <p style={{ fontWeight: "bold" }}>1. Scope of Use</p>
      <p>
        Please read the following General Terms of Use (“Terms”) carefully. By
        accessing this website{" "}
        <a href="https://www.yelofunding.com" target="_blank" rel="noreferrer">
          (www.yelofunding.com)
        </a>{" "}
        (the “Site”), you agree to be bound by these Terms. Access to and use of
        this Site is subject to these Terms and all applicable laws and
        regulations. These Terms are subject to change at any time, and your use
        of this Site constitutes agreement to all such changes. YOU MAY NOT USE
        THE SITE IF YOU DO NOT AGREE TO THESE TERMS.
      </p>
      <p>
        YELO Funding, Inc., and its affiliates (“YELO”) provides this Site to
        you, the user of the Site (“you” or “your”), for your informational,
        noncommercial use, and subject to the following Terms. For the purpose
        of the following Terms, references to “we”, “us” and “our” include YELO
        and its affiliates, subsidiaries, agents, representatives, successors
        and assigns. Using the Site to evaluate whether to enter into a business
        relationship with us will not constitute a commercial use for the
        purposes hereof. It is a violation of these Terms for you to use the
        Site in violation of any applicable laws and regulations or in violation
        of the rules of any of our service providers. Certain other programs or
        services provided by us through linked websites or other channels may
        have additional terms and conditions regarding your use of those
        services, and nothing in these Terms is intended to modify such terms
        and conditions. Subject your compliance with these Terms and all
        applicable international, federal, state, and local laws, rules, and
        regulations, we grant you a limited, revocable, nonexclusive,
        non-sublicenseable, non-transferable, license to use the Site solely for
        your own personal use and not for republication, distribution,
        assignment, sublicense, sale, preparation of derivative works, or other
        use.
      </p>
    </Stack>

    <Stack direction="column" gap={2}>
      <p style={{ fontWeight: "bold" }}>2. Restrictions on Use</p>
      <p>
        You will not use the Site for any use other than the business purpose
        for which it was intended. You will not, and will not permit any third
        party to, take any of the following actions with respect to the Site or
        the server hosting the Site nor will you use our Site to upload, post,
        email, distribute, transmit, link, solicit or otherwise make available
        any content or use the Site in any manner that: (i) uploads or transmits
        any unsolicited advertising, promotional materials, “junk mail”, “spam”,
        “chain letters”, “pyramid schemes”, or any other form of solicitation,
        commercial or otherwise; (ii) decompiles, uses reverse engineering,
        disassembles, derives the source code of or decrypts the Site or server
        hosting the Site; (iii) manipulates or otherwise displays the Site by
        using framing, mirroring or similar navigational technology or directly
        links to any portion of the Site other than the main homepage (i.e.
        deep-linking) located at www.yelofunding.com; (iv) uses any robot,
        spider, scraper or other automatic or manual means to access the Site or
        copies any content or information on the Site; (v) removes, obscures, or
        alters any proprietary notices (including any notice of copyright or
        trademark) of us or our affiliates, partners, suppliers or our
        licensors; (vi) modifies, adapts, improves, enhances or makes any
        derivative work from the Site; (vii) disables, overburdens, impairs or
        otherwise interferes with or interrupts the Site or any hardware,
        software, system or network connected with the Site; (viii) probes,
        scans, or tests the vulnerability of or breach the authentication
        measures of the Site or any related networks or systems; (ix) interferes
        with any other party’s use and enjoyment of the Site; (x) infringes the
        copyright, trademark or any proprietary rights or discloses a trade
        secret or confidential information in violation of a confidentiality or
        non-disclosure agreement; (xi) compiles, uses, downloads or otherwise
        copies the Site or any user information or any portion thereof, or
        transmits, provides or otherwise distributes (whether or not for a fee)
        the Site or such information to any third party; (xii) is fraudulent,
        malicious or unlawful, unauthorized or contains defamatory or illegal
        information, images, materials or descriptions; (xiii) promotes or
        provides instructions for illegal activities; (xiv) encourages any
        conduct that would constitute a criminal offense or that gives rise to
        civil liability; (xv) disseminates viruses or other computer code, files
        or programs that interrupt, destroy or limit the functionality of any
        computer software or hardware; (xvi) attempts to gain unauthorized
        access to any other accounts, computer systems or networks connected to
        any server or systems through hacking, password mining or any other
        means; or (xvii) accesses systems, data or information that we do not
        intend to be made accessible to you. Use of the Site is limited to
        persons thirteen (13) years of age or older.
      </p>
    </Stack>

    <Stack direction="column" gap={2}>
      <p style={{ fontWeight: "bold" }}>3. Privacy Notice</p>
      <p>
        You may view a copy of our privacy policy here{" "}
        <a href="/privacy-policy" target={"_blank"}>
          http://www.yelofunding.com/privacy-policy
        </a>{" "}
        (“Privacy Notice”), which explains our practices relating to the
        collection and use of your information through or in connection with our
        Site. The Privacy Notice is incorporated into these Terms and governs
        our use of your information and/or any information you submit or
        otherwise make available to us in connection with the Site.
      </p>
    </Stack>

    <Stack direction="column" gap={2}>
      <p style={{ fontWeight: "bold" }}>4. Registration, Access & Security</p>
      <p>
        Certain content offered on this Site may require you to provide certain
        information. If you use any of our services or provide us any
        information through the Site, such as your name, social security number,
        previous work experience, education background, birth date, age,
        criminal history, citizenship status, address, zip code, phone number,
        contact details, email address, and/or your password (collectively, your
        “Account Data”) for any accounts associated with you (your
        “Account(s)”), you agree to provide true, accurate, current, complete
        and up-to-date information. We reserve the right to take any action that
        we deem necessary to ensure the security of the Site and your Account,
        including without limitation changing your password, terminating your
        Account, or requesting additional information to authorize transactions
        on your Account. You are solely responsible for keeping your Account
        Data and any security questions and responses associated with your
        Account confidential. You are solely liable for any claims, damages,
        losses, costs, or other liabilities resulting from or caused by any
        failure to keep your Account Data and the security questions and
        responses confidential, whether such failure occurs with or without your
        knowledge or consent. If you suspect that your password has been
        compromised in any way, change your password immediately. You will
        immediately notify us of any suspected or actual unauthorized access to
        or use of your Account Data or any other breach of your Account
        security.
      </p>
    </Stack>

    <Stack direction="column" gap={2}>
      <p style={{ fontWeight: "bold" }}>
        5. No Legal, Financial or Professional Advice Given
      </p>
      <p>
        Your individual circumstances and needs may vary from those of others.
        As such, nothing on this Site should be deemed financial or professional
        advice by YELO to you in any way. If you choose to enter into an
        agreement with YELO for a product or service, you should not rely on the
        information provided by this Site. You should make your decision based
        on your review of the application, disclosures, services descriptions,
        the terms, conditions, exclusions and limitations of the specific
        products, and the advice provided to you by your agent and/or other
        advisors before making your decision.
      </p>
    </Stack>

    <Stack direction="column" gap={2}>
      <p style={{ fontWeight: "bold" }}> 6. No Warranties</p>
      <p>
        THE SITE AND ALL MATERIALS ON THE SITE ARE PROVIDED TO YOU ON AN “AS-
        IS,” “AS-AVAILABLE” BASIS AND WE EXPRESSLY DISCLAIM ALL WARRANTIES OF
        ANY KIND EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE
        IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE
        AND NON-INFRINGEMENT. WE MAKE NO WARRANTY AS TO THE ACCURACY,
        COMPLETENESS, CURRENCY OR RELIABILITY OF THE SITE OR ANY SERVICES,
        PRODUCTS, INFORMATION, OPINIONS, AND MATERIALS AVAILABLE THROUGH THE
        SITE. YOU ARE RESPONSIBLE FOR VERIFYING ANY INFORMATION YOU OBTAIN FROM
        THE SITE BEFORE RELYING ON IT. USE OF THE SITE IS AT YOUR SOLE RISK. WE
        MAKE NO REPRESENTATIONS OR WARRANTIES THAT USE OF THE SITE OR THE
        MATERIALS PROVIDED THROUGH THE SITE WILL BE UNINTERRUPTED, COMPLETELY
        SECURE, VIRUS-FREE, OR ERROR-FREE. EXCEPT AS EXPRESSLY SET FORTH IN
        SECTION 12 BELOW, YOUR SOLE AND EXCLUSIVE REMEDY RELATING TO YOUR USE OF
        THE SITE SHALL BE TO DISCONTINUE USING THE SITE.
      </p>
    </Stack>

    <Stack direction="column" gap={2}>
      <p style={{ fontWeight: "bold" }}>7. Trademarks</p>
      <p>
        All trademarks, service marks and logos that are used or displayed on
        this Site are owned by us or our licensors. You must obtain our written
        permission prior to using any trademark or service mark of ours.
        Unauthorized use of any trademarks, service marks or logos used on the
        Site may be a violation of state, national and international trademark
        laws. Additionally, our custom icons, graphics, logos and scripting on
        the Site may be covered by trademark, trade dress, copyright or other
        proprietary right law, and may not be copied, modified or used, in whole
        or in part, without our prior written permission. Only with notices of
        YELO’s proprietary rights provided you may download information and
        print out hard copies for your personal use, so long as you do not
        remove any copyright or other notice as may be contained in information,
        as downloaded.
      </p>
    </Stack>

    <Stack direction="column" gap={2}>
      <p style={{ fontWeight: "bold" }}> 8. Copyright Information</p>
      <p>
        Pursuant to Section 512(c)(2) of the Copyright Act, YELO designates the
        following agent to receive notifications of claimed copyright or
        intellectual property infringement:
      </p>
      <Stack direction="column">
        <p>Dan Rubin</p>
        <p>1325 Avenue of the Americas, 28th Floor, New York, NY 10019</p>
        <p>(929) 525-2282</p>
        <a href="mailto:info@yelofunding.com">info@yelofunding.com</a>
      </Stack>
    </Stack>

    <Stack direction="column" gap={2}>
      <p style={{ fontWeight: "bold" }}>
        9. Reviews, Comments and Other Content
      </p>
      <p>
        If you post or submit any reviews, comments, photos, statements, ideas,
        questions or other content, or any names or user names associated with
        any of the foregoing, to the Site or to us (collectively, the
        “Content”), you acknowledge and agree that all such Content will comply
        with these Terms (including, without limitation, Section 2 above) and
        you may not use any fake e-mail address or impersonate any other person
        or entity or otherwise mislead as to the origin of the Content. Unless
        we indicate otherwise, you grant us an irrevocable, perpetual, fully
        paid up, royalty-free, enterprise wide, worldwide license to copy,
        modify, sell, create derivative works from, or otherwise use the Content
        on any media and in any form for our business purposes. You represent
        and warrant that all Content that you submit or post complies with any
        applicable guidelines or rules of the United States Federal Trade
        Commission, including but not limited to FTC 16 CFR Part 255, regarding
        truth-in advertising and disclosure requirements. You represent and
        warrant that all Content you submit to the Site or us is accurate,
        truthful and non-deceptive and that all Content has evidence to
        substantiate the claims made.
      </p>
    </Stack>

    <Stack direction="column" gap={2}>
      <p style={{ fontWeight: "bold" }}>
        10. Violation of Rules and Regulations; Disclosure of Information
      </p>
      <p>
        We reserve the right to seek all remedies available at law and in equity
        for violations of the rules and regulations set forth in the Site,
        including, without limitation, these Terms, including the right to block
        access from a particular Internet address to the Site. We may cooperate
        with legal authorities and/or third parties in the investigation of any
        suspected or alleged crime or civil wrong. Except as may be expressly
        limited by the Privacy Notice, we reserve the right at all times to: (i)
        disclose any information as we deem necessary to satisfy any applicable
        law, regulation, legal process or governmental request; or (ii) edit,
        refuse to post or to remove any information or materials, in whole or in
        part, as applicable, in our sole discretion.
      </p>
    </Stack>

    <Stack direction="column" gap={2}>
      <p style={{ fontWeight: "bold" }}> 11. Indemnity</p>
      <p>
        You agree to indemnify, defend, and hold us and our subsidiaries,
        affiliates, officers, agents, employees, contractors, partners and
        licensors harmless from and against any and all suits, actions, losses,
        claims, proceedings, demands, expenses, damages, settlements, judgments,
        injuries, liabilities, obligations, risks, and costs, including, without
        limitation, reasonable attorneys’ fees and litigation expenses, due to,
        relating to, or arising out of your use of the Site. This
        indemnification will survive any termination of these Terms.
      </p>
    </Stack>

    <Stack direction="column" gap={2}>
      <p style={{ fontWeight: "bold" }}>12. Limitation of Liability</p>
      <p>
        YOU UNDERSTAND AND AGREE THAT WE WILL NOT BE LIABLE FOR ANY DIRECT,
        INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE, EXEMPLARY, OR SPECIAL
        DAMAGES, WHETHER BASED IN CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE,
        ARISING OUT OF OR IN ANY WAY CONNECTED WITH ACCESS TO OR USE OF THE
        SITE, INCLUDING, WITHOUT LIMITATION, ANY MATERIALS, SERVICES AND/OR
        PRODUCTS WE HAVE PROVIDED TO YOU ON OR THROUGH THE SITE, WHETHER OR NOT
        YOU HAVE PURCHASED OR PROVIDED ANY CONSIDERATION FOR SUCH, EVEN IF WE
        HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, INCLUDING BUT NOT
        LIMITED TO: (A) ANY ACTION TAKEN IN CONNECTION WITH AN INVESTIGATION BY
        LAW ENFORCEMENT AUTHORITIES REGARDING YOUR OR ANY OTHER PARTY’S USE OF
        THE SITES; (B) ANY ACTION TAKEN IN CONNECTION WITH COPYRIGHT OR OTHER
        INTELLECTUAL PROPERTY OWNERS; (C) ANY DAMAGE TO YOUR OR ANY OTHER USER’S
        COMPUTER, MOBILE DEVICE, OR OTHER EQUIPMENT OR TECHNOLOGY; (D) RELIANCE
        BY ANY PARTY ON ANY CONTENT OBTAINED THROUGH USE OF THE SITE; OR (E)
        WHETHER CAUSED IN WHOLE OR IN PART BY NEGLIGENCE, ACTS OF GOD,
        TELECOMMUNICATIONS FAILURE, THEFT OR DESTRUCTION OF, OR UNAUTHORIZED
        ACCESS TO THE SITE, OR RELATED INFORMATION OR PROGRAMS, THAT ARISE IN
        CONNECTION WITH: (1) MISTAKES OR OMISSIONS IN, OR DELAYS IN TRANSMISSION
        OF, INFORMATION TO OR FROM YOU; (2) INTERRUPTIONS IN TELECOMMUNICATIONS
        CONNECTIONS TO THE SITE; OR (3) VIRUSES.
      </p>
      <p>
        OUR TOTAL LIABILITY TO YOU FROM ALL CAUSES OF ACTION AND UNDER ALL
        THEORIES WILL BE LIMITED TO THE AMOUNT YOU HAVE PAID FOR THE USE OF THE
        SITE, IF ANY, AND IF YOU HAVE PAID NO AMOUNT, THEN FIFTY UNITED STATES
        DOLLARS ($50.00). YOU ALSO ACKNOWLEDGE AND AGREE THAT YOU HAVE VIEWED OR
        USED THE SITE WITH A FULL UNDERSTANDING OF THE LIMITATION OF OUR
        LIABILITY IN THESE TERMS. BY ACCESSING THE SITE, YOU UNDERSTAND THAT YOU
        MAY BE WAIVING RIGHTS WITH RESPECT TO CLAIMS THAT ARE AT THIS TIME
        UNKNOWN OR UNSUSPECTED, AND IN ACCORDANCE WITH SUCH WAIVER, YOU
        ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND, AND HEREBY EXPRESSLY
        WAIVE, THE BENEFITS OF SECTION 1542 OF THE CIVIL CODE OF CALIFORNIA, AND
        ANY SIMILAR LAW OF ANY STATE OR TERRITORY, WHICH PROVIDES AS FOLLOWS: “A
        GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT
        KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE
        RELEASE, WHICH IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS
        SETTLEMENT WITH THE DEBTOR.”
      </p>
      <p>
        Some jurisdictions do not allow exclusions of liability for certain
        types of damages. Accordingly, some of the above limitations may not
        apply to you to the extent prohibited by law. In such cases, our
        liability will be limited to the fullest extent permitted by applicable
        law.
      </p>
    </Stack>

    <Stack direction="column" gap={2}>
      <p style={{ fontWeight: "bold" }}>13. Third Party Services Sites</p>
      <p>
        We may engage certain affiliates, subcontractors, or other third parties
        to provide all or part of the Site to you, including payment processing
        services, and you hereby acknowledge and agree that such third-party
        involvement is acceptable. These third-party services may have their own
        associated terms of services and license agreements that you will agree
        to when you use these third-party services. You agree that any
        interaction with these third parties will be governed by the terms of
        service between you and each individual third party and that the YELO
        shall not be held liable for any issues that may arise in connection
        with your use of these third-party services, regardless of any real or
        potential involvement of YELO in the provisioning of those services.{" "}
      </p>
      <p>
        Some links in the Site may navigate you away from the Site or redirect
        you to other websites, including websites operated by third parties. The
        linked third-party websites are not under our control, and the content
        available on the linked third-party websites does not necessarily
        reflect our opinion or imply our recommendation or endorsement of the
        linked third-party website or the opinions expressed therein. We are not
        responsible for the privacy practices of any other websites. Please be
        aware that those websites may collect personally identifiable
        information (“PII”) from or about you as well as non-PII about your
        visit. You should review the terms of use and privacy policies that are
        posted on any website that you visit, before using any linked websites.
      </p>
      <p>
        We are providing these links to other websites as a convenience to you,
        and access to any other websites linked to the Site is at your own risk.
        We are under no obligation to maintain any link on the Site and we may
        remove a link at any time in our sole discretion for any reason
        whatsoever. YELO disclaims liability for any information, materials,
        products, or services available at any of the third party sites linked
        to this Site.
      </p>
    </Stack>

    <Stack direction="column" gap={2}>
      <p style={{ fontWeight: "bold" }}>14. No Fiduciary Relationship</p>
      <p>
        Except to the extent set forth in a separate agreement between you and
        us, there is no fiduciary relationship between you and us. These Terms
        do not create any relationship of principal and agent, partnership,
        joint venture, or employer and employee, between you and us. You may not
        enter into any contract on our behalf or bind us in any way.
      </p>
    </Stack>

    <Stack direction="column" gap={2}>
      <p style={{ fontWeight: "bold" }}>15. Right to Monitor</p>
      <p>
        We reserve the right to actively monitor the use of the Site and use any
        information gathered during such monitoring for any permissible purpose
        under the Privacy Notice. Additionally, we may, at any time as we deem
        appropriate, remove any materials from the Site that, in our sole
        discretion, may be illegal, may subject us to liability, may violate
        these Terms, or are, in our sole discretion, inconsistent with our
        purpose for the Site.
      </p>
    </Stack>

    <Stack direction="column" gap={2}>
      <p style={{ fontWeight: "bold" }}>16. Electronic Communications</p>
      <p>
        When you visit the Site or send e-mails to us, you are communicating
        with us electronically and you consent to receive communications from us
        electronically. We will communicate with you by e-mail at the address we
        have on file for you (if any), sending you messages through the mobile
        application we provide, or by posting notices on the Site. You agree
        that all agreements, notices, disclosures and other communications that
        we provide to you electronically satisfy any legal requirement that such
        communications be in writing. You further agree that any notices
        provided by us electronically are deemed to be given and received on the
        date we transmit any such electronic communication as described in these
        Terms.
      </p>
      <p>
        Additionally, by submitting an online application, you agree that we may
        send you certain messages via automated phone calls and SMS messages,
        including telemarketing messages. If you choose to update your contact
        information with us, including cellular telephone numbers, we may use it
        for to communicate with you. We will not charge you for a communication,
        but your service provider may. In addition, you understand and agree we
        and any of our affiliates, agents, service providers or assignees may
        always communicate with you in any manner permissible by law that does
        not require your prior consent. For more information, please see our
        TCPA Consent at{" "}
        <a href="/tcpa-consent" target={"_blank"}>
          https://www.yelofunding.com/tcpa-consent
        </a>
        .
      </p>
    </Stack>

    <Stack direction="column" gap={2}>
      <p style={{ fontWeight: "bold" }}>17. Notices</p>
      <p>
        All notices required or permitted under these Terms to us will be in
        writing and sent by certified mail, return receipt requested, or by
        reputable oversight courier, or by hand delivery, provided that we may
        provide written notice to you through electronic communications as
        described in the paragraph immediately above. The notice address for
        YELO Funding, Inc. is 1325 Avenue of the Americas, 28th Floor, New York,
        NY 10019. Any notice sent in the manner sent forth above shall be deemed
        sufficiently given for all purposes hereunder (i) in the case of
        certified mail, on the second business day after deposited in the U.S.
        mail, and (ii) in the case of overnight courier or hand delivery, upon
        delivery. We may change our notice address by giving written notice to
        you by the means specified in this Section.
      </p>
    </Stack>

    <Stack direction="column" gap={2}>
      <p style={{ fontWeight: "bold" }}>
        18. Use Outside of the United States; Choice of Law; and Venue
      </p>
      <p>
        The Site is operated by us from our offices within the United States of
        America. We make no representation that the information in the Site is
        appropriate or available for use in other locations, and access to the
        Site from territories where the contents of the Site may be illegal is
        prohibited. Those who choose to access the Site from other locations do
        so, on their own initiative and are responsible for compliance with
        applicable local laws. By using the Site, regardless of where you live
        or are located in the world, you consent to these Terms and any claims
        relating to the information, services or products made available through
        the Site will be governed by the laws of the State of New York, without
        regard to conflicts of laws provisions to the contrary. The parties
        hereby opt out of the Uniform Computer Information Transaction Act to
        the fullest extent permitted by law. You agree that venue for all
        actions, relating in any manner to these Terms, will be in a federal or
        state court of competent jurisdiction located in New York County, New
        York.
      </p>
    </Stack>

    <Stack direction="column" gap={2}>
      <p style={{ fontWeight: "bold" }}>19. Time Limit on Claims Against Us</p>
      <p>
        You agree that any claim you may have arising out of or related to your
        use of the Site or your relationship with us must be filed within one
        (1) year after such claim arose; otherwise, your claim is permanently
        barred.
      </p>
    </Stack>

    <Stack direction="column" gap={2}>
      <p style={{ fontWeight: "bold" }}>20. Severability and Waiver</p>
      <p>
        If any provision of these Terms will be deemed unlawful, void, or for
        any reason unenforceable, then that provision will be deemed severable
        from these Terms and will not affect the validity and enforceability of
        any remaining provisions. Our failure to enforce the strict performance
        of any provision of these Terms will not constitute a waiver of our
        right to subsequently enforce such provision or any other provisions of
        these Terms.
      </p>
    </Stack>

    <Stack direction="column" gap={2}>
      <p style={{ fontWeight: "bold" }}>21. Assignment</p>
      <p>
        We may assign these Terms or any part of them without restriction or
        condition. You may not assign or otherwise transfer these Terms or your
        rights under these Terms without our prior written consent and any
        assignment in violation of this prohibition will be null and void.
      </p>
    </Stack>

    <Stack direction="column" gap={2}>
      <p style={{ fontWeight: "bold" }}> 22. Our Remedies</p>
      <p>
        You agree that any violation, or threatened violation, by you of these
        Terms constitutes an unlawful and unfair business practice that will
        cause us irreparable and unquantifiable harm. You also agree that
        monetary damages would be inadequate for such harm and consent to our
        obtaining any injunctive or equitable relief that we deem necessary or
        appropriate. These remedies are in addition to any other remedies we may
        have at law or in equity.
      </p>
    </Stack>
  </Stack>
);

const applicationAndSoliciationDisclosureFile =
  process.env.PUBLIC_URL + "/files/YELO_Application_Disclosure.pdf";

export const applicationAndSolicitationDisclosureContent = (
  <Stack direction="column" gap={2}>
    <p style={{ textAlign: "center" }}>
      Please download and review the Application Disclosure
    </p>

    <Stack
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        border: "1px solid #ced5ec",
        borderRadius: "3rem",
        padding: "2rem",
      }}
    >
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 2,
        }}
      >
        <img src={pdfIcon} alt="" />
        <a
          href={applicationAndSoliciationDisclosureFile}
          download
          style={{
            textDecoration: "none",
            color: "#203864",
            pointerEvents: "none",
          }}
          id="download"
        >
          Application Disclosure
        </a>
      </Stack>
      <img
        src={downloadIcon}
        alt=""
        style={{ cursor: "pointer" }}
        onClick={() => document.getElementById("download").click()}
      />
    </Stack>
  </Stack>
);

export const EDASCContent = (
  <Stack direction="column" gap={2}>
    <p>
      Please read this information carefully and print a copy and/or retain this
      information electronically for future reference.
    </p>
    <p>
      YELO Funding, Inc., its affiliates, including YELO ISA Fund I, LLC, and/or
      any person or entity acting on behalf of or servicing your agreement for
      the aforementioned entities (collectively, “we”, “us” or “YELO”) are
      required to provide each applicant, account owner and anyone else with
      access to the account (“you” or “your”) with certain written disclosures,
      documents, and information related to your transactions with YELO.
    </p>

    <p>
      We need your consent (“E-Consent”) to use and accept electronic signatures
      (“Electronic Signatures”) and electronic disclosures (“Electronic
      Records”). This form notifies you of your rights when receiving and
      signing Electronic Records.
    </p>

    <p>
      <span style={{ fontWeight: "bold" }}>
        Option for Paper or Non-Electronic Records.
      </span>
      You may request any Electronic Records in paper by calling us at (646) 770-1080, or mailing to 1325 Avenue of the Americas, 28th Floor, New York,
      NY 10019. We will provide paper copies at no charge.
    </p>

    <p>
      <span style={{ fontWeight: "bold" }}>Scope of Consent.</span>
      This E-Consent applies to all your income share agreement applications and
      all other transactions with us. This includes all documents that YELO will
      provide to you or agreements that you will execute with YELO such as
      execution of all account agreements, disclosures, statements, and
      documents relating to your ISA Account in electronic format, execution of
      any modifications to such documents, and the use of electronic
      communications, electronic statements, electronic contracts, and
      electronic signatures (including “checkbox” style acknowledgments).
    </p>

    <p>
      <span style={{ fontWeight: "bold" }}>
        Consenting to Do Business Electronically.
      </span>
      Before you decide to do business electronically with YELO, you should
      consider whether you have the required hardware and software capabilities
      described below. If you do not have the hardware and software required to
      access and retain the Electronic Records, or if you do not agree to the
      use of Electronic Records and Electronic Signatures, you will not be able
      to receive any electronic communications, information and disclosures
      provided to you over the course your income share agreement with YELO.
    </p>

    <p>
      <span style={{ fontWeight: "bold" }}>
        Hardware and software requirements for accessing, electronically signing
        and retaining electronic records.
      </span>
      To access, electronically sign and retain the Electronic Records, you will
      need to use the following computer software and hardware, which may be
      updated from time to time: (i) a personal computer with any of the
      following operating systems: Windows XP or higher, OS X (Apple Macintosh)
      or higher, or (ii) a mobile device with internet access. You will need one
      of the following web browsers, or a more recent version of these web
      browsers, installed: Google Chrome (latest version), Internet Explorer
      (latest version). Microsoft Edge (latest version), Safari (versions 10 and
      above), Mozilla Firefox (latest version), iOS 10 & above (iPhone), Android
      5+. Please save and print a copy of this E-Consent to confirm that you
      have the required hardware and software to conduct electronic transactions
      with us.
    </p>

    <p>
      <span style={{ fontWeight: "bold" }}>
        The ability to receive e-mail that contains hyperlinks to websites in
        order for us to deliver information to you.
      </span>
      To access the Required Information being provided to you electronically,
      you must have software which permits you to receive, print and access
      Portable Document Format or “PDF” files, such as Adobe Acrobat Reader®. If
      you do not have such software, click <a href="https://get.adobe.com/reader/" target="_blank" rel="noreferrer">here</a> to download a
      free version of Adobe Acrobat Reader®.
    </p>
    <p>
      <span style={{ fontWeight: "bold" }}>
        Sufficient electronic storage capacity on your computer’s hard drive or
        other data storage unit or the ability to print PDF files from your
        computer or tablet device.
      </span>
      During the enrollment process, you will be sent an email that contains
      information pertaining to proceeding with your application. You will be
      required to access and open this email in order to demonstrate your
      ability to receive and retain electronic records using the email address
      that you have provided.
    </p>
    <p>
      {" "}
      <span style={{ fontWeight: "bold" }}>Withdrawing consent.</span>
      You are free to withdraw this E-Consent at any time and at no charge, but
      doing so will not apply to any Electronic Signature that you provide us
      before withdrawing this E-Consent. To withdraw your consent prior to
      completing your application, simply exit this session prior to accepting
      this Agreement. To withdraw your consent after you have already submitted
      your application, you must contact us at{" "}
      <a href="mailto:info@yelofunding.com">info@yelofunding.com</a>. If you
      withdraw your consent to this Agreement, you will no longer receive the
      electronic presentment of any documents.
    </p>
    <p>
      <span style={{ fontWeight: "bold" }}>
        Change to your contact information.
      </span>
      You should keep us informed of any change in your electronic address or
      mailing address. If you change your mailing address and or email address,
      you must promptly notify us and provide us with your new email address
      either by logging into your account and updating your email address or by
      contacting us by email at{" "}
      <a href="mailto:info@yelofunding.com">info@yelofunding.com</a>.
    </p>
    <p>
      CONSENT. BY GIVING YOUR CONSENT BELOW, YOU ACCEPT THE TERMS OF THIS
      CONSENT FOR ELECTRONIC RECORDS AND ELECTRONIC SIGNATURES, YOU ACKNOWLEDGE
      AND AGREE THAT: (1) YOU HAVE READ THIS INFORMATION ABOUT ELECTRONIC
      SIGNATURES AND ELECTRONIC RECORDS; (2) YOU CAN ACCESS THE ELECTRONIC
      RECORDS IN THE FORMATS DESIGNATED IN THIS E-CONSENT; AND (3) YOU CONSENT
      TO USING ELECTRONIC SIGNATURES AND ELECTRONIC RECORDS.
    </p>
  </Stack>
);
