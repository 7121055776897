import { useSnackbar } from "notistack";
import React, {useEffect, useState} from "react";
import { useFormContext } from "react-hook-form";
import { Box, Input, LoaderIndicator } from "..";
import { uploadFile } from "../../../api/uploads";
import checkIcon from "../../../assets/icons/checkmark_green.svg";
import imageIcon from "../../../assets/icons/image.svg";
import { getFormErrorMsg } from "../../../helpers/formErrorMessages";
import "./FileUpload.scss";

const FileUploadField = ({
  id,
  name,
  type,
  fileUploadType,
  onSuccess,
  allowedTypes,
  title,
  uploadLabel,
  isReadonly = false,
  defaultValue = null,
  ...rest
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState();
  const [selectedFile, setSelectedFile] = useState();

  const {
    register,
    formState: { errors },
    setValue,
    clearErrors,
    setError,
  } = useFormContext();

  const handleFileUpload = (file) => {
    setLoading(true);

    uploadFile({ file, type: fileUploadType })
      .then((res) => {
        enqueueSnackbar("File successfully uploaded", {
          variant: "success",
        });

        setValue(name, res.data["@id"]);
        clearErrors(name);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setSelectedFile();
        setValue(name, undefined);
        setError(name, "This document is required");
        setDescription();
        console.log("Error uploading file", err);
        enqueueSnackbar("Error uploading file", {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    if (defaultValue) {
      setSelectedFile(defaultValue.filename);
      setDescription(defaultValue.filename);
    }
  }, [defaultValue]);

  return (
    <div className="file-upload">
      <div
        style={{
          fontSize: "1.6rem",
          fontWeight: 500,
          paddingLeft: "2.5rem",
          marginBottom: "1rem",
        }}
      >
        {title}
      </div>
      <Box
        className="flex-column align-center justify-center outlined dotted"
        gap={2}
        {...rest}
      >
        {!selectedFile ? (
          <div className="flex-column align-center justify-center">
            <div style={{ textAlign: "center" }}>{uploadLabel}</div>
            <Input
              {...register(name)}
              type="file"
              accept={allowedTypes.join(", ")}
              label={"Click here to upload an image"}
              onChange={(file) => {
                if (isReadonly) return


                if (file !== undefined) {
                  if (file?.target?.files?.[0]?.size < 100000000) {
                    setSelectedFile(file.target.files[0]);
                    setDescription(file.target.files[0].name);
                    handleFileUpload(file.target.files[0]);
                  } else {
                    setDescription("");
                  }
                }
              }}
              hidden
            />
          </div>
        ) : loading ? (
          <LoaderIndicator />
        ) : (
          <div className="flex-column align-center justify-center">
            <div
              className="flex-column align-center justify-center"
              style={{
                border: "6px solid #4DCA22",
                height: 70,
                width: 70,
                borderRadius: "100px",
                padding: "1rem",
              }}
            >
              <img src={checkIcon} alt="" />
              <div
                style={{ color: "#4DCA22", fontWeight: 500, fontSize: "16px" }}
              >
                Done
              </div>
            </div>
            <div style={{ marginTop: "2rem" }}>{description}</div>
          </div>
        )}
      </Box>
      <div style={{ marginLeft: "2rem", marginTop: "1rem", color: "#B7C0DF" }}>
        Supported formats: {allowedTypes.join(", ")}
      </div>
      {selectedFile && description && (
        <div className="flex justify-between align-center upload-container">
          <div className="flex justify-center align-center">
            <img src={imageIcon} alt="" />
            <div style={{ marginLeft: "1rem" }}>{description}</div>
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (isReadonly) return;
              setValue(name, undefined);
              setError(name, "This document is required");
              setSelectedFile();
              setDescription();
            }}
          >
            Remove
          </div>
        </div>
      )}
      {Boolean(getFormErrorMsg(errors, name)) && (
        <div
          className="error-hint"
          style={{ color: "red", marginLeft: "2rem" }}
        >
          {getFormErrorMsg(errors, name)}
        </div>
      )}
    </div>
  );
};

export default FileUploadField;
