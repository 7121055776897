import React from "react";
import { useParams } from "react-router-dom";

import CustomCard from "../../components/common/card/Card";
import useGetBlogRelatedArticles from "../../hooks/useGetBlogRelatedArticles/useGetBlogRelatedArticles";
import "./BlogRelatedArticles.scss";

const BlogRelatedArticle = () => {
  const { slug } = useParams();
  const { data } = useGetBlogRelatedArticles({ slug });

  const sortedArticles = data?.sort((a, b) => {
    return new Date(b.publishedAt) - new Date(a.publishedAt);
  }, []);

  if (!data) {
    return null;
  }

  return (
    <section className="section__innerWrapper">
      <div className="related-articles">
        <h2 className="related-articles__section-title">Related Articles</h2>
        <div className="related-articles__container">
          {sortedArticles?.map((article) => (
            <CustomCard key={article.id} data={article} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default BlogRelatedArticle;
