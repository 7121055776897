import React, { useState, useEffect, useRef } from "react";
import { Button, Stack } from "@mui/material";
import pdfIcon from "../../../assets/icons/pdf.svg";
import downloadIcon from "../../../assets/icons/download.svg";
import "./DocumentsModal.scss";

const applicationAndSoliciationDisclosureFile =
  process.env.PUBLIC_URL + "/files/YELO_Application_Disclosure.pdf";
const glbaNoticeFile = process.env.PUBLIC_URL + "/files/YELO_GLBA_Notice.pdf";

const DocumentDownloadSection = ({
  id,
  title,
  file,
  onClick,
  error,
  clicked,
  text,
}) => (
  <Stack
    direction="column"
    gap={1}
    marginBottom={2}
  >
    <Stack
      sx={{
        cursor: "pointer",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        border: "1px solid #ced5ec",
        borderRadius: "3rem",
        padding: "1rem 1.5rem",
        background: "linear-gradient(90deg, transparent, #f9f9f9)",
        transition: "200ms ease-in-out",
        "&:hover": {
          borderColor: "#F15642",
        },
      }}
      onClick={onClick}
    >
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 2,
        }}
      >
        <img
          src={pdfIcon}
          alt="PDF Icon"
          width="30"
          height="35"
        />
        <span
          style={{
            color: "#203864",
            pointerEvents: "none",
            lineHeight: 1,
          }}
          id={`download-${id}`}
        >
          {title}
        </span>
      </Stack>
      <img
        src={downloadIcon}
        alt="Download"
        width="20"
        height="20"
      />
    </Stack>
    <p
      style={{
        textAlign: "left",
        marginInline: "2rem",
        lineHeight: "1.2",
        opacity: clicked[id] ? 0 : 1,
        transition: "opacity 1s",
      }}
    >
      {text}
    </p>
    {!clicked[id] && error && (
      <div
        style={{
          fontSize: "1.4rem",
          color: "#fd746b",
          marginLeft: "2rem",
        }}
      >
        Please download and review the file
      </div>
    )}
  </Stack>
);

const DocumentsModalContent = ({
  documents,
  handleClose,
  handleAccept,
  ...props
}) => {
  const [selected, setSelected] = useState(documents?.[0]);
  const bodyRef = useRef(null);
  const scrollableContentRef = useRef(null);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [clicked, setClicked] = useState({});
  const [error, setError] = useState(false);

  const handleScroll = () => {
    setButtonState();
  };

  const setButtonState = () => {
    if (bodyRef.current && scrollableContentRef.current) {
      setIsScrolledToBottom(
        bodyRef.current.scrollTop + bodyRef.current.clientHeight >=
          scrollableContentRef.current.clientHeight - 20
      );
    }
  };

  useEffect(() => {
    const bodyElement = bodyRef.current;

    const handleTouchMove = () => {
      setButtonState();
    };

    const handleScroll = () => {
      setButtonState();
    };

    if (bodyElement) {
      bodyElement.addEventListener("scroll", handleScroll);
      bodyElement.addEventListener("touchmove", handleTouchMove);

      setButtonState();
    }

    return () => {
      if (bodyElement) {
        bodyElement.removeEventListener("scroll", handleScroll);
        bodyElement.removeEventListener("touchmove", handleTouchMove);
      }
    };
  }, [scrollableContentRef]);

  const handleTermAccept = () => {
    if (
      (selected.id === "glba-notice" ||
        selected.id === "application-disclosure") &&
      !clicked[selected.id]
    ) {
      setError(true);
      return;
    }

    const nextDocIndex =
      documents.findIndex((doc) => doc.id === selected.id) + 1;

    if (nextDocIndex !== documents.length) {
      setSelected(documents[nextDocIndex]);
      setError(false);
    } else {
      if (clicked["application-disclosure"] && clicked["glba-notice"]) {
        handleAccept();
        handleClose();
      } else {
        setError(true);
      }
    }

    if (bodyRef.current) {
      bodyRef.current.scrollTop = 0;
    }
    setButtonState();
  };

  const handleDownloadClick = (docId, fileUrl) => {
    setClicked((prev) => ({ ...prev, [docId]: true }));
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("target", "_blank");
    link.setAttribute("rel", "noopener noreferrer");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };  

  const isFinalStep = selected?.id === "final-step";
  const allFilesDownloaded =
    clicked["application-disclosure"] && clicked["glba-notice"];

  return (
    <div
      {...props}
      className="documents-modal"
    >
      <div className="list">
        <div className="inner-wrapper">
          {documents.map((doc) => (
            <div
              key={doc.id}
              className={`list-element${
                selected?.id === doc.id ? " selected" : ""
              }`}
              onClick={() => setSelected(doc)}
            >
              {doc.title}
            </div>
          ))}
        </div>
      </div>
      <div className="flex-column align-center justify-center content">
        <h2>{selected?.title}</h2>
        <div
          className="body"
          onScroll={handleScroll}
          ref={bodyRef}
        >
          <div
            className="scrollable-content"
            ref={scrollableContentRef}
          >
            {isFinalStep ? (
              <Stack
                direction="column"
                gap={1}
                mt={3}
              >
                <DocumentDownloadSection
                  id="application-disclosure"
                  title="Application Disclosure"
                  file={applicationAndSoliciationDisclosureFile}
                  onClick={() =>
                    handleDownloadClick(
                      "application-disclosure",
                      applicationAndSoliciationDisclosureFile
                    )
                  }
                  error={error}
                  clicked={clicked}
                  text="Please download and review the Application Disclosure"
                />
                <DocumentDownloadSection
                  id="glba-notice"
                  title="GLBA Notice"
                  file={glbaNoticeFile}
                  onClick={() =>
                    handleDownloadClick("glba-notice", glbaNoticeFile)
                  }
                  error={error}
                  clicked={clicked}
                  text="Please download and review the GLBA Notice"
                />
                {error && (
                  <div
                    style={{
                      fontSize: "1.4rem",
                      color: "#fd746b",
                      marginLeft: "2rem",
                    }}
                  >
                    Please download and review all the files
                  </div>
                )}
              </Stack>
            ) : (
              selected?.text
            )}
          </div>
        </div>
        <Button
          className="primary"
          onClick={(e) => {
            e.preventDefault();
            handleTermAccept();
          }}
          disabled={!isScrolledToBottom || (isFinalStep && !allFilesDownloaded)}
          sx={{ textTransform: "capitalize" }}
        >
          {selected?.id === documents?.[documents.length - 1]?.id
            ? "Accept & Finish"
            : "Accept & Continue"}
        </Button>
      </div>
    </div>
  );
};

export default DocumentsModalContent;
