import React, { useEffect, useState } from "react";
import { object, string } from "yup";
import { updateFundingApplication } from "../../../api/funding-application";
import {
  Button,
  FileUploadField,
  Form,
  SSNInputField,
} from "../../../components/common";
import { useCurrentFundingApplication } from "../../../hooks";
import "./Documents.scss";

const validationSchema = object({
  verifyIdentity: string().required("This is a required field"),
  uploadTranscript: string().required("This is a required field"),
  backOfDrivingLicense: string().required("This is a required field"),
  selfiePicture: string().required("This is a required field"),
  recentResume: string().required("This is a required field"),
  proofOfAddress: string().required("This is a required field"),
  socialSecurityNumber: string()
    .required("This is a required field")
    .test("isSSNValid", "This is not a valid social security number", (v) => {
      if (!v) return false;
      return v.replaceAll("-", "").replaceAll(/\s/g, "").length === 9;
    }),
  // agreement: boolean().isTrue("You must agree to the terms above"),
});

const Documents = ({ onProceed, isReadonly = false }) => {
  const [currentApplication, fundingApplicationId] =
    useCurrentFundingApplication();
  const [readonlyData, setReadonlyData] = useState({});

  const handleSubmit = async (data) => {
    // if is readonly mode - just change view
    if (isReadonly) {
      onProceed("terminated");
      return;
    }

    const {
      verifyIdentity,
      uploadTranscript,
      socialSecurityNumber,
      backOfDrivingLicense,
      selfiePicture,
      recentResume,
      proofOfAddress,
    } = data;

    let submitData = {
      transition: "to_terminated",
      socialSecurityNumber,
      uploads: [
        verifyIdentity,
        uploadTranscript,
        backOfDrivingLicense,
        selfiePicture,
        recentResume,
        proofOfAddress,
      ],
    };

    try {
      await updateFundingApplication({
        data: submitData,
        id: fundingApplicationId,
      }).then(() => {
        onProceed("terminated");
        localStorage.setItem("old_dashboard", true);
      });
    } catch (e) {
      console.log("Error: ", e);
    }

    // Track the event
    window.gtag("event", "submitted_finish", {
      event_category: "Form Submission",
      event_label: "Completed Documents Form",
      value: 1,
    });

    window.ttq.track("submitted_finish");
  };
  useEffect(() => {
    document.body.classList.add("documents");
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });

    return () => {
      document.body.classList.remove("documents");
    };
  }, []);

  useEffect(() => {
    if (!isReadonly) return;

    setReadonlyData({
      identity: {
        defaultValue: currentApplication?.uploads?.filter(
          (file) => file.type === "TYPE_ID"
        )[0],
        isReadonly: true,
      },
      transcript: {
        defaultValue: currentApplication?.uploads?.filter(
          (file) => file.type === "TYPE_TRANSCRIPT"
        )[0],
        isReadonly: true,
      },
      socialSecurityNumber: {
        defaultValue: currentApplication?.socialSecurityNumber,
        isReadonly: true,
      },
      back_driver: {
        defaultValue: currentApplication?.uploads?.filter(
          (file) => file.type === "TYPE_DLB"
        )[0],
        isReadonly: true,
      },
      selfie_driver: {
        defaultValue: currentApplication?.uploads?.filter(
          (file) => file.type === "TYPE_SDL"
        )[0],
        isReadonly: true,
      },
      cor: {
        defaultValue: currentApplication?.uploads?.filter(
          (file) => file.type === "TYPE_COR"
        )[0],
        isReadonly: true,
      },
      poa: {
        defaultValue: currentApplication?.uploads?.filter(
          (file) => file.type === "TYPE_POA"
        )[0],
        isReadonly: true,
      },
    });
  }, [isReadonly, currentApplication]);

  return (
    <div className="step-wrapper personal-info flex-column align-center step--documents">
      <h1>Last steps, promise! Upload documents.</h1>
      <Form onSubmit={handleSubmit} validationSchema={validationSchema}>
        <div className="flex-column gap-3rem">
          <SSNInputField
            id="socialSecurityNumber"
            name="socialSecurityNumber"
            placeholder="XXX-XX-XXXX"
            labelAbove="Social Security Number"
            {...readonlyData.socialSecurityNumber}
            required
          />
          <FileUploadField
            id="verifyIdentity"
            name="verifyIdentity"
            title={"Verify Identity"}
            uploadLabel={
              "Upload a copy of your ID (driver license, passport or State issued ID)"
            }
            helperText={"Supported formats: .jpeg, .png, .pdf"}
            allowedTypes={[".jpeg", ".png", "pdf"]}
            fileUploadType={"TYPE_ID"}
            {...readonlyData.identity}
            required
          />
          <FileUploadField
            id="backOfDrivingLicense"
            name="backOfDrivingLicense"
            labelAbove={"Back of Driving license"}
            helperText={"Supported formats: .jpeg, .png, .pdf"}
            allowedTypes={[".jpeg", ".png", "pdf"]}
            title={"Back of Driving license"}
            uploadLabel={"Upload Back of DL"}
            fileUploadType={"TYPE_DLB"}
            {...readonlyData.back_driver}
            required
          />
          <FileUploadField
            id="uploadTranscript"
            name="uploadTranscript"
            labelAbove={"Upload Transcript"}
            helperText={"Supported formats: .jpeg, .png, .pdf"}
            allowedTypes={[".jpeg", ".png", "pdf"]}
            title={"Upload Transcript"}
            uploadLabel={"Upload your most recent unofficial transcript"}
            fileUploadType={"TYPE_TRANSCRIPT"}
            {...readonlyData.identity}
            required
          />
          <FileUploadField
            id="selfiePicture"
            name="selfiePicture"
            labelAbove={"A selfie picture of you holding your driver's license"}
            helperText={"Supported formats: .jpeg, .png, .pdf"}
            allowedTypes={[".jpeg", ".png", "pdf"]}
            title={"A selfie picture of you holding your driver's license"}
            uploadLabel={"Upload Selfie"}
            fileUploadType={"TYPE_SDL"}
            {...readonlyData.selfie_driver}
            required
          />
          <FileUploadField
            id="recentResume"
            name="recentResume"
            labelAbove={"A copy of your most recent resume"}
            helperText={"Supported formats: .jpeg, .png, .pdf"}
            allowedTypes={[".jpeg", ".png", "pdf"]}
            title={"A copy of your most recent resume"}
            uploadLabel={"Upload Resume"}
            fileUploadType={"TYPE_COR"}
            {...readonlyData.cor}
            required
          />
          <FileUploadField
            id="proofOfAddress"
            name="proofOfAddress"
            labelAbove={"A proof of address: utility bill or bank statement"}
            helperText={"Supported formats: .jpeg, .png, .pdf"}
            allowedTypes={[".jpeg", ".png", "pdf"]}
            title={"A proof of address: utility bill or bank statement"}
            uploadLabel={
              "Upload utility bill / bank statement as proof of residency"
            }
            fileUploadType={"TYPE_POA"}
            {...readonlyData.poa}
            required
          />
          <Button
            className="primary full-width xl"
            type="submit"
            label="Finish application"
            onClick={() => {
              if (isReadonly) {
                handleSubmit();
                return false;
              }
              return true;
            }}
          />
        </div>
      </Form>
    </div>
  );
};

export default Documents;
